/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {User} from "@firebase/auth-types";
import Loading from '../components/Loading';
import Comment from "../components/Comment";
import './EntryDetail.scss';
import Rating from '@material-ui/lab/Rating';
import {Button, TextField} from "@material-ui/core";
import HashMap from 'hashmap';

export default function EntryDetail({match, user}: { match: any, user: User | null }) {
  const {id} = match.params
  const [loading, setLoading] = useState<boolean>(false);
  const [entry, setEntry] = useState<any>(null);
  const [keyOp, setKeyOp] = useState<boolean>(true);
  const [newStatus, setNewStatus] = useState<number>(0);
  const keyOpRef = useRef<boolean>();
  const location: any = useLocation();
  keyOpRef.current = keyOp;
  const entryRef = useRef<any>();
  entryRef.current = entry;
  const [images, setImages] = useState<any[]>([
    'https://placehold.jp/150x150.png?text=Loading',
    'https://placehold.jp/150x150.png?text=Loading',
    'https://placehold.jp/150x150.png?text=Loading'
  ]);
  const history = useHistory();

  const statusText = new HashMap<number, string>([
    [1, "書類提出"],
    [2, "3次面接 - 日程調整中"],
    [3, "3次面接 - 日程確定"],
    [4, "最終面談"],
    [99, "NG"],
  ]);

  useEffect(() => {
    if (user) {
      fetchDetail().then();
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      fetchDetail().then();
    }
  }, [id]);
  const keydownHandler = useCallback(async (e) => {
    if (keyOpRef.current) {
      if (document.getElementsByClassName('fancybox-container').length === 0) {
        if (e.keyCode === 37 && entryRef.current.navi.prev_id) {
          (location.state.entries) ?
            history.push(`/detail/${entryRef.current.navi.prev_id}`, {entries: location.state.entries}) :
            history.push(`/detail/${entryRef.current.navi.prev_id}`);
        }
        if (e.keyCode === 39 && entryRef.current.navi.next_id) {
          (location.state.entries) ?
            history.push(`/detail/${entryRef.current.navi.next_id}`, {entries: location.state.entries}) :
            history.push(`/detail/${entryRef.current.navi.next_id}`);
        }
      }
      if (e.keyCode === 48) {
        await changeRating(0);
      }
      if (e.keyCode === 49) {
        await changeRating(1);
      }
      if (e.keyCode === 50) {
        await changeRating(2);
      }
      if (e.keyCode === 51) {
        await changeRating(3);
      }
      if (e.keyCode === 52) {
        await changeRating(4);
      }
      if (e.keyCode === 53) {
        await changeRating(5);
      }
    }
  }, []);
  useEffect(() => {
    // console.log(entry);
    document.addEventListener("keydown", keydownHandler, false);
  }, []);
  const fetchDetail = async () => {
    setLoading(true);
    try {
      const {data} = await axios.get(`https://app.q-uad.me/api/entry/${id}.json`, {
        headers: {
          "x-access-token": "602dff7d80b15453b737e2f82d9859cd7537fd8f6d21d67126184875805285ad",
          "x-firebase-user-id": user?.uid
        }
      });
      setEntry(() => {
        if (location.state?.entries) {
          const idx = location.state.entries.findIndex((e: any) => e.id === id);
          data.result.entry.navi.next_id = (idx > 0) ? location.state.entries[idx - 1].id : null;
          data.result.entry.navi.prev_id = (idx < location.state.entries.length - 1) ? location.state.entries[idx + 1].id : null;
        }
        return data.result.entry;
      });
      images[0] = data.result.pictures.bust_up;
      images[1] = data.result.pictures.full_body;
      images[2] = data.result.pictures.picture;
      setImages(images);
      fancybox();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  const changeRating = async (value: number | null) => {
    const metaData = entryRef.current.meta_data ?? {"rating": [], "comments": []};
    const idx: number = metaData.rating.findIndex((x: any) => x.email === user?.email);
    if (idx >= 0) {
      metaData.rating[idx] = {
        "email": user?.email,
        "rate": value
      }
    } else {
      metaData.rating.push({
        "email": user?.email,
        "rate": value
      })
    }
    entryRef.current.meta_data = metaData;
    setEntry(Object.assign({}, entryRef.current));
    await putMetaData(entryRef.current.id, metaData);
  }
  const putMetaData = async (id: string, metaData: any) => {
    try {
      await axios.put(`https://app.q-uad.me/api/meta/${id}.json`, {
          metaData
        },
        {
          headers: {
            "content-type": "application/json",
            "x-access-token": "602dff7d80b15453b737e2f82d9859cd7537fd8f6d21d67126184875805285ad",
            "x-firebase-user-id": user?.uid
          }
        })
    } catch (error) {
      console.error(error)
    } finally {
    }
  }
  const renderPaging = (entry: any) => {
    return (
      <>
        {entry &&
        <div className="paging" style={{padding: "10px 0"}}>
          {entry.navi.prev_id &&
          <button style={{margin: "0 5px"}} className="button is-small is-primary"
                  onClick={
                    () => {
                      history.push(`/detail/${entry.navi.prev_id}`, {entries: location.state?.entries})
                    }
                  }> 前の応募者 </button>
          }
          {entry.navi.next_id &&
          <button style={{margin: "0 5px"}} className="button is-small is-primary"
                  onClick={() => {
                    history.push(`/detail/${entry.navi.next_id}`, {entries: location.state?.entries})
                  }}
          > 次の応募者 </button>
          }
        </div>
        }
      </>
    )
  }
  return (
    <div className="container">
      {loading ? <Loading/> :
        <>
          <div className="detail-box">
            <div className="text-data-wrapper">
              {renderPaging(entry)}
              <div className="div-block">
                <div className="div-block-2">
                  <div className="text-block">ステータス</div>
                  <div className="text-block-2">
                    <div className="select">
                      <select defaultValue={entry?.status} onChange={(e) => {
                        console.log(parseInt(e.currentTarget.value));
                        setNewStatus(parseInt(e.currentTarget.value))
                      }}>
                        {
                          statusText.entries().map((value) => {
                            return <option key={`status-${value[0]}`} value={value[0]}>{value[1]}</option>
                          })
                        }
                      </select>
                    </div>
                    <button className="button is-warning" onClick={() => {
                      axios.put(`https://app.q-uad.me/api/status/${entry?.id}.json`, {
                        status: newStatus
                      }, {
                        headers: {
                          "x-access-token": "602dff7d80b15453b737e2f82d9859cd7537fd8f6d21d67126184875805285ad",
                          "x-firebase-user-id": user?.uid
                        }
                      }).then(() => {
                        alert("ステータスを変更しました");
                      }).catch((err) => {
                        alert(err.message);
                      })
                    }}>
                      変更する
                    </button>
                  </div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">User ID</div>
                  <div className="text-block-2">
                    <div>
                      <input className="input is-inline is-small" value={entry?.line_user_id} />
                      <button className="is-danger button is-small" onClick={
                        async (e) => {
                          try {
                            await axios.post("https://app.q-uad.me/api/audience/create.json", {
                                description: `${entry?.entry_data.name}さん`,
                                userIDs: [entry?.line_user_id]
                              },
                              {
                                headers: {
                                  "x-access-token": "602dff7d80b15453b737e2f82d9859cd7537fd8f6d21d67126184875805285ad",
                                  "x-firebase-user-id": user?.uid
                                }
                              });
                            alert("オーディエンスを作成しました");
                          } catch (error) {
                            console.error(error);
                          }
                        }
                      }>オーディエンス作成
                      </button>
                    </div>
                  </div>
                </div>

                <div className="div-block-2">
                  <div className="text-block">フリガナ</div>
                  <div className="text-block-2">{entry?.entry_data.name_kana}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">名前</div>
                  <div className="text-block-2">{entry?.entry_data.name}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">大学</div>
                  <div className="text-block-2">{entry?.entry_data.university}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">学部・学科・学年</div>
                  <div className="text-block-2">{entry?.entry_data.faculty}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">メールアドレス</div>
                  <div className="text-block-2">{entry?.entry_data.email}</div>
                </div>
                <div className="div-block-2">
                  <div className="text-block">電話番号</div>
                  <div className="text-block-2">{entry?.entry_data.tel}</div>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3">今ハマっているものについて、教えて下さい</div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.obsessed_things.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3">将来実現してみたい夢はなんですか？</div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.dream.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block-3">目標としている人を教えてください</div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.aim_for.replace(/\n/g, '<br />')
                  }}/>
                </div>
                <div className="div-block-3">
                  <div className="text-block">自己PR</div>
                  <div className="text-block-2" dangerouslySetInnerHTML={{
                    __html: entry?.entry_data.pr.replace(/\n/g, '<br />')
                  }}/>
                </div>
              </div>
              {renderPaging(entry)}
            </div>
            <div className="image-wrapper">
              <div style={{"display": "flex", "flexDirection": "row", "alignItems": "center"}}>
                <span className="tag is-primary">全体の評価</span>
                <Rating name="rating-average" readOnly value={
                  entry?.meta_data?.rating.reduce((acc: number, cur: any) => acc + cur.rate, 0) / entry?.meta_data?.rating?.length ?? 0
                } precision={0.1} size="large"/>
                <span>
                {entry?.meta_data?.rating.reduce((acc: number, cur: any) => acc + cur.rate, 0) / entry?.meta_data?.rating?.length ?? 0}/5
              </span>
              </div>
              <div style={{"display": "flex", "flexDirection": "row", "alignItems": "center", "marginTop": "15px"}}>
                <span className="tag is-danger">あなたの評価</span>
                <Rating name="rating" onChange={async (event, value) => {
                  await changeRating(value);
                }} value={entry?.meta_data?.rating?.find((x: any) => x.email === user?.email)?.rate ?? 0}
                        size="large"/>
                <span>
                {entry?.meta_data?.rating?.find((x: any) => x.email === user?.email)?.rate ?? 0}/5
              </span>
              </div>

              <hr/>
              <a href={images[0]} data-fancybox="gallery">
                <img
                  src={images[0]}
                  loading="lazy"
                  sizes="(max-width: 767px) 96vw, (max-width: 991px) 291.65625px, 380px" alt=""/>
              </a>
              <div className="div-block-5">
                <div className="image-box">
                  <a href={images[1]} data-fancybox="gallery">
                    <img
                      src={images[1]}
                      loading="lazy"
                      sizes="(max-width: 767px) 44vw, (max-width: 991px) 125.828125px, 170px" alt=""/>
                  </a>
                </div>
                <div className="image-box">
                  <a href={images[2]} data-fancybox="gallery">
                    <img
                      src={images[2]}
                      loading="lazy"
                      sizes="(max-width: 767px) 44vw, (max-width: 991px) 125.828125px, 170px" alt=""/>
                  </a>
                </div>
              </div>
              <hr/>
              <div className="comment-wrapper">
                {
                  entry?.meta_data?.comments?.map((val: any) => (
                    <Comment author={val.author} at={val.at} body={val.body}/>))
                }
                <TextField
                  id="new-comment"
                  label="コメントを記入"
                  multiline
                  rows={4}
                  variant="outlined"
                  style={{width: "100%"}}
                  onFocus={(e) => {
                    console.log(e);
                    setKeyOp(false);
                  }}
                  onBlur={(e) => {
                    setKeyOp(true);
                  }}
                />
                <Button className="is-warning button" onClick={
                  async (e) => {
                    const text: any = document.getElementById("new-comment");
                    if (text.value) {
                      const metaData = entry?.meta_data ?? {"rating": [], "comments": []};
                      if (metaData.comments) {
                      } else {
                        metaData.comments = [];
                      }
                      metaData.comments?.unshift({
                        "author": user?.email,
                        "at": (new Date()).toLocaleString(),
                        "body": text.value
                      });
                      entry.meta_data = metaData;
                      setEntry(Object.assign({}, entry));
                      await putMetaData(entry?.id, metaData);
                      text.value = "";
                    }
                  }
                }> 保存 </Button>
                {renderPaging(entry)}
              </div>
            </div>
          </div>
        </>
        }
        </div>
        );

        function fancybox() {
        try {
        const global: any = window;
        const query: any = global.$('a[data-fancybox]');
        query &&
        query.fancybox({
        loop: true,
        infobar: false,
        buttons: ['close']
      });
      } catch {
      }
      }
      }
